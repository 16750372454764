import React from "react";
import posts from "../data/posts.json";
import { Link } from "react-router-dom";
import sanitizeHtml from "sanitize-html";
import { useParams } from "react-router-dom";

export default function PostsSidebar() {
  let { title } = useParams();
  title = decodeURIComponent(title);
  title = title.replaceAll("-", " ");

  posts.reverse();

  return (
    <>
      <div className="col-lg-3 side-bar">
        <div className="mb-3">
          <h4>Expand Your Educational Insights</h4>
          <small className="text-muted">
            Explore educational insights, trends, and perspectives in our
            related posts. Dive deeper into knowledge, broaden your horizons,
            and enhance your educational journey.
          </small>
        </div>
        {posts.slice(0, 4).map((post, index) => {
          if (title === post.title) {
            return;
          }
          return (
            <div
              className="list-group list-group-flush border-top border-bottom"
              key={index}
            >
              <Link
                to={
                  "/posts/" +
                  encodeURIComponent(post.title.replaceAll(" ", "-"))
                }
                className="list-group-item list-group-item-action lh-tight px-0"
              >
                <div className="w-100 align-items-center justify-content-between">
                  <strong className="mb-1">{post.title}</strong> <br />
                  {/* <small className="text-muted">{post.date}</small> */}
                </div>
                <div className="col-12 mb-1 small">
                  {sanitizeHtml(post.content, {
                    allowedTags: [],
                    allowedAttributes: {},
                  }).slice(0, 100)}
                  ...
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
}
