import React from "react";
import { FaLinkedin, FaTwitter, FaResearchgate } from "react-icons/fa";
import { SiResearchgate } from "react-icons/si";
import { Link } from "react-router-dom";
import team_data from "../data/team";

export default function TeamMembers() {
  const social_icons = {
    linkedin: <FaLinkedin />,
    twitter: <FaTwitter />,
    researchgate: <SiResearchgate />,
  };

  const team = team_data();

  return (
    <>
      <div className="container-fluid team-container py-4">
        {team.map((member, index) => {
          return (
            <div className="card team-member" key={index}>
              <div className="row g-1">
                <div className="col-md-5" style={{ minHeight: "16rem" }}>
                  <img
                    src={member.image}
                    alt=""
                    className="img-fluid rounded-start fill-parent"
                  />
                </div>
                <div className="col-md-7">
                  <div className="card-body">
                    <h5 className="card-title mb-1">{member.name}</h5>
                    <p className="">
                      <small className="text-muted">{member.designation}</small>{" "}
                      <br />
                      <small className="card-text">{member.bio}</small>
                    </p>
                    <div className="d-flex gap-2 border-top pt-1">
                      {Object.entries(member.social).map((arr, index) => {
                        if (arr[1] === "nil") {
                          return;
                        }
                        return (
                          <Link to={arr[1]} key={arr[1]}>
                            {social_icons[arr[0]]}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
