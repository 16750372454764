import React from "react";
import { Link } from "react-router-dom";

// Images
import img_1 from "../assets/images/african-woman-teaching-children-class.jpg";
import img_2 from "../assets/images/african-kids-paying-attention-class.jpg";
import img_3 from "../assets/images/group-african-kids-learning-together.jpg";

export default function Carousel() {
  return (
    <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="1"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="2"
        ></button>
      </div>

      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={img_1} alt="" className="bd-placeholder-img" />
          <div className="container">
            <div className="carousel-caption text-start">
              <h1>Touching Lives, Our Priority!</h1>
              <p>
                We foster educational development in Ogbomoso, through planning,
                planting, and organization of educational institutions in
                Ogbomoso land and beyond.
              </p>
              <p>
                <Link className="btn btn-lg" to="/our-story">
                  Learn More
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img src={img_2} alt="" className="bd-placeholder-img" />
          <div className="container">
            <div className="carousel-caption text-start">
              <h1>We See the Need for a Positive Change in Academics</h1>
              <p>
                We are a group of Academic Staff of Nigeria's Tertiary
                Institutions, and of Ogbomoso extraction. We come together
                towards the same goal and objectives to foster educational
                development in Ogbomoso, through planning, planting, and
                organization of educational institutions in Ogbomoso land and
                beyond.
              </p>
              <p>
                <Link className="btn btn-lg" to="#">
                  Join Us
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <img src={img_3} alt="" className="bd-placeholder-img" />
          <div className="container">
            <div className="carousel-caption text-start">
              <h1>Our Core Objectives</h1>
              <p>
                To ensure educational opportunities are made available to its
                growing citizenry that abound throughout the nooks and crannies
                of Nigeria and beyond. To enlighten the larger Ogbomoso
                community worldwide and ensure an educated community. To
                encourage the youths in our community to seek knowledge and
                education, which is the bedrock of any potentially developing
                society.
              </p>
              <p>
                <Link className="btn btn-lg" to="/our-story">
                  Read More
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}
