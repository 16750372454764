import React from "react";
import { useParams } from "react-router";
import Posts from "../data/posts.json";
import NotFound from "./404";
import PostsSidebar from "../components/posts-sidebar";
import { safeHtml } from "../utils/sanitizeHtml/app";
import { FaCalendar, FaPen } from "react-icons/fa";

export default function SinglePost() {
  let { title } = useParams();
  title = decodeURIComponent(title);
  title = title.replaceAll("-", " ");

  const post = getPost();

  function getPost() {
    for (const post of Posts) {
      if (post.title === title) {
        return post;
      }
    }
    return undefined;
  }

  if (post == undefined) {
    return <NotFound more="posts" />;
  } else {
    return (
      <>
        <div
          className="container-fluid mb-5"
          style={{ height: "16rem", backgroundColor: "#bbb" }}
        ></div>

        <div className="container pt-5">
          <div
            className="container-fluid row justify-content-between"
            style={{ margin: "0px" }}
          >
            <div className="col-lg-8 main-content">
              <div className="mb-3">
                <h1 className="display-5">{post.title}</h1>
                <div className="d-flex">
                  <small className="text-muted my-2 me-3">
                    <FaCalendar style={{ marginRight: "10px" }} /> {post.date}
                  </small>
                  <small className="text-muted my-2 me-3">
                    <FaPen style={{ marginRight: "10px" }} /> {post.author}
                  </small>
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: safeHtml(post.content),
                }}
              ></div>
            </div>
            <PostsSidebar />
          </div>
        </div>
      </>
    );
  }
}
