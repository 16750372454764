import React from "react";
import { Routes, Route } from "react-router-dom";

import NavBar from "./components/navbar";
import Footer from "./components/footer";

import Home from "./pages/home";
import OurStory from "./pages/our-story";
import Posts from "./pages/posts";
import SinglePost from "./pages/post-single";
import CoreTeam from "./pages/core-team";
import Events from "./pages/events";
import NotFound from "./pages/404";
import ScrollToTop from "./utils/ScrollToTop";

function App() {
  return (
    <>
      <NavBar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/our-story" element={<OurStory />} />
        <Route path="/core-team" element={<CoreTeam />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/posts/:title" element={<SinglePost />} />
        <Route path="/events" element={<Events />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
