import React from "react";
import TeamMembers from "../components/team-members";

export default function CoreTeam() {
  const land_committee = [
    {
      name: "Prof. Akintola",
      designation: "Chairman",
    },
    {
      name: "Prof. J. K. Oyewumi",
      designation: "Member",
    },
  ];

  const adhoc_members = [
    {
      name: "Prof. Razaq O. Rom Kalilu",
      designation: "Chairman",
    },
    {
      name: "Prof. Abdulazeez Lawal",
      designation: "Member",
    },
    {
      name: "Prof. Siyan Peters",
      designation: "Member",
    },
    {
      name: "Prof. Alice Jekayinfa",
      designation: "Member",
    },
    {
      name: "Prof. Misbhaudeen Abdul-Hammed",
      designation: "Member",
    },
    {
      name: "Dr. Abdulrazaq Kunle Ayinla",
      designation: "Member",
    },
  ];
  return (
    <>
      <div
        className="container-fluid"
        style={{ height: "16rem", backgroundColor: "#bbb" }}
      ></div>
      <div className="container py-5">
        <div className="container-fluid">
          <h1 className="display-5">
            Members of Ogbomoso Academics Executive Committee.{" "}
          </h1>
          <p className="text-muted">
            Get to know the faces behind our mission as we introduce you to the
            inspiring individuals who make up our team. From academic staff to
            administrators, from mentors to motivators, our team is united by a
            common vision of transforming lives through education. Join us in
            celebrating the remarkable contributions of our team members as we
            strive to create a brighter future for generations to come.
          </p>
        </div>
        <TeamMembers />
      </div>
      <div className="container-fluid bg-light mb-5">
        <div className="container py-5">
          <h1 className="display-6">
            Members of Ogbomoso Academics, Land Acquisition Committee.
          </h1>
          <p className="text-muted">
            The Land Acquisition Committee is committed to securing suitable
            land for the establishment of educational institutions that will
            shape the future of our community. As a collective force, our
            committee members bring a wealth of expertise, knowledge, and
            dedication to the table. With a shared vision of providing
            accessible and high-quality education, we work diligently to
            identify, evaluate, and acquire prime land locations that will serve
            as the foundation for educational initiatives.
          </p>
          <List_members data={land_committee} col={3} />
        </div>
      </div>
      <div className="container-fluid mb-5">
        <div className="container">
          <h1 className="display-6">Ad-hoc Take-off Campus Committee.</h1>
          <p className="text-muted">
            The Ad-hoc Take-off Campus Committee serves as a bridge, enabling us
            to start delivering educational opportunities to the community while
            our permanent campuses are being developed. We recognize the
            significance of immediate access to education and aim to make a
            positive impact on the lives of our students and the community at
            large.
          </p>
          <List_members data={adhoc_members} col={3} />
        </div>
      </div>
    </>
  );
}

function List_members({ data, col }) {
  return (
    <div className={`row row-cols-1 row-cols-md-${col} g-3 pt-3`}>
      {data.map((value, index) => {
        return (
          <div className="col" key={index}>
            <div className="card h-100">
              <div className="card-body">
                <h6 className="card-title">{value.name}</h6>
                <small className="card-text text-muted">
                  {value.designation}
                </small>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
