import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/brand_logo.png";
import navbar_css from "../styles/navbar.css";
import global from "../data/global.json";

export default function NavBar() {
  return (
    <>
      {/* NAV BAR */}
      <nav className="navbar sticky-top navbar-expand-md navbar-light bg-light px-lg-5 px-md-3 px-sm-0 py-lg-4 py-md-4 py-sm-2">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img src={logo} />
            <span>{global.brand.name}</span>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <div className="navbar-nav ms-auto">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to="/">
                    Home
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    id="avbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    About
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <Link className="dropdown-item" to="our-story">
                        Our Story
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="core-team">
                        Our Members
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="events">
                    Events and Programmes
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="posts">
                    Posts
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="gallery">
                    Gallery
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
