import React from "react";
import PostGrid from "../components/posts-grid";

export default function Posts() {
  return (
    <>
      <div className="container">
        <PostGrid />
        <p className="text-muted pt-4 border-top">
          <small>
            Calling all passionate educators, researchers, and thought leaders!
            Join our community and share your valuable insights on education.
            Submit your articles and be featured on our website, inspiring
            others and shaping the future of education. Join us in fostering
            growth and transforming lives through the power of shared knowledge.
            Submit your articles today and be part of the Ogbomoso Academics
            movement.
          </small>
        </p>
      </div>
    </>
  );
}
