import React from "react";
import objectives from "../data/objectives.json";

// images
import header_img from "../assets/images/group-african-kids-paying-attention-class.jpg";

export default function OurStory() {
  return (
    <>
      <div
        className="container-fluid header_img"
        style={{ backgroundImage: `url(${header_img})` }}
      ></div>
      <div className="container mt-5">
        <div className="container-fluid mb-5">
          <h1 className="display-3">Ogbomoso Academics</h1>
          <p>
            Ogbomoso Academics, where Touching Lives is Our Priority! We are a
            collective group of Academic Staff from Nigeria's Tertiary
            Institutions, all with deep roots in Ogbomoso. Our mission is to
            drive educational development in Ogbomoso and beyond through
            strategic planning, establishment, and organization of educational
            institutions. At Ogbomoso Academics, we recognize the urgent need
            for a positive change in academics. Our primary objective is to
            ensure that educational opportunities are accessible to all,
            reaching every nook and cranny of Nigeria and beyond. We are
            dedicated to enlightening the global Ogbomoso community and
            fostering an educated society.
          </p>

          <p>
            One of our core goals is to inspire the youth in our community to
            actively pursue knowledge and education, as they are the bedrock of
            a thriving and developing society. We strive to cultivate a sense of
            harmony among the educated, the less educated, and the vulnerable
            individuals within Ogbomosoland, as well as those residing in the
            diaspora. As champions of progress, we encourage and support
            developmental projects aimed at creating well-equipped educational
            institutions staffed by qualified personnel. Our vision is to build
            a community that is visible, friendly, peaceful, and transparent,
            with viable industrial potentials and resourceful individuals.
          </p>
          <p>
            Integrity lies at the heart of our organization. We aspire to be the
            vanguards of truth, honesty, and integrity in every corner of
            Ogbomosoland, fostering unity amidst our diversity in class,
            religion, education, and residential areas. As you explore our
            website, you will discover the myriad ways in which Ogbomoso
            Academics aims to touch lives and make a lasting impact. Together,
            we can bring about positive change, uplift individuals, and
            contribute to the overall development of Ogbomosoland.
          </p>
          <p>
            Join us on this transformative journey as we place educational
            development at the forefront of our endeavors. Together, let us
            shape a brighter future for Ogbomoso and beyond.
          </p>
        </div>
      </div>
      <div className="container-fluid bg-light py-5 mb-5">
        <div className="container">
          <h6 className="display-6">Vision and Mission</h6>
          <p>
            Our vision is to empower education and create a brighter future
            driven by motivation, harmony, values, and growth. We believe in the
            transformative power of education to inspire individuals, foster
            community harmony, instill core values, and nurture personal and
            intellectual growth.
          </p>
          <div className="row row-cols-1 row-cols-md-4 g-4 mt-3">
            <div className="col">
              <h5>Motivation</h5>
              <p className="text-muted">
                Encouraging the youths in our community to seek knowledge and
                education.
              </p>
            </div>
            <div className="col">
              <h5>Harmony</h5>
              <p className="text-muted">
                Seeking community and interpersonal harmony amidst the citizens
                of Ogbomosoland.
              </p>
            </div>
            <div className="col">
              <h5>Values</h5>
              <p className="text-muted">
                Promating truth, honesty and integrity in every community of
                Ogbomosoland.
              </p>
            </div>
            <div className="col">
              <h5>Growth</h5>
              <p className="text-muted">
                Engaging in developmental projects for a better learning
                platform for the youth.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <Objectives col={3} />
      </div>
    </>
  );
}

export function Objectives({ col }) {
  return (
    <div className="container-fluid mb-5">
      <h2 className="display-6">
        Fostering Educational Opportunities, Empowering Communities
      </h2>
      <p>
        We are driven by a diverse range of objectives that form the foundation
        of our work. Our initiatives are aimed at fostering educational
        opportunities, enlightening communities, empowering youth, promoting
        harmony, encouraging development, and upholding truth and integrity.
        Together, we strive to make a lasting impact on Ogbomosoland and beyond.
        Our objectives include:
      </p>
      <div className={`row row-cols-1 row-cols-md-${col} g-3 pt-3`}>
        {objectives.map((value, index) => {
          return (
            <div className="col" key={index}>
              <div className="card h-100">
                <div className="card-body">
                  <h6 className="card-title">{value.title}</h6>
                  <small className="card-text text-muted">{value.body}</small>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
