import Carousel from "../components/carousel";
import PostSlider from "../components/posts-slider";
import SideBar from "../components/sidebar";
import { Objectives } from "./our-story";

export default function Home() {
  return (
    <div>
      <Carousel />
      <div className="container pt-5">
        <div
          className="container-fluid row justify-content-between"
          style={{ margin: "0px" }}
        >
          <div className="col-lg-8 main-content">
            <Objectives col={2} />
            <h6 className="text-primary display-6">
              Unleashing the Power of Education: Exploring Insights and
              Inspiration
            </h6>
            <p>
              Discover a wealth of educational insights and inspiration through
              our blog. Explore a diverse range of topics, gain valuable
              perspectives, and empower your mind with knowledge. Our blog posts
              offer a platform for engaging discussions and transformative
              ideas. Join us on this journey of discovery and educational
              empowerment.
            </p>
            <PostSlider />
          </div>
          <SideBar />
        </div>
      </div>
    </div>
  );
}
