import React from "react";
import Posts from "../data/posts.json";
import { Link } from "react-router-dom";
import { safeText } from "../utils/sanitizeHtml/app";
import { FaCalendar, FaPen } from "react-icons/fa";

export default function PostSlider() {
  return (
    <div className="slider-container mt-4 mb-5">
      {Posts.map((post, index) => {
        return (
          <div className="card slider-card" key={index}>
            <Link
              to={
                "/posts/" + encodeURIComponent(post.title.replaceAll(" ", "-"))
              }
            >
              <div className="card-img-top"></div>
              <div className="card-body">
                <h5 className="card-title post-card-title">{post.title}</h5>
                <p className="card-text post-card-text">
                  {safeText(post.content)}
                </p>
                <div className="d-flex" style={{ fontSize: "13px" }}>
                  <small className="text-muted my-2 me-3">
                    <FaCalendar style={{ marginRight: "10px" }} /> {post.date}
                  </small>
                  <small className="text-muted my-2 me-3">
                    <FaPen style={{ marginRight: "10px" }} /> {post.author}
                  </small>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
}
