import React from "react";
import {
  FaTwitter,
  FaFacebook,
  FaMapMarker,
  FaPhone,
  FaEnvelope,
} from "react-icons/fa";
import footer_css from "../styles/footer.css";
import global from "../data/global.json";

export default function Footer() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <div className="footer mt-5">
      <div className="container">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-5 px-4">
          <div className="col-md-4 col-12 align-items-center">
            <p className="mb-3 mb-md-0">
              © {currentYear} {global.brand.name}
            </p>
            <p>
              <small className="text-light">
                <FaMapMarker style={{ marginRight: "10px" }} />
                {global.brand.address}
              </small>
              <br />
              <small className="text-light">
                <FaPhone style={{ marginRight: "10px" }} />
                {global.brand.phone.join(" | ")}
              </small>
              <small className="text-light">
                <br />
                <FaEnvelope style={{ marginRight: "10px" }} />
                {global.brand.email}
              </small>
            </p>
          </div>

          <ul className="col-md-4 justify-content-end list-unstyled d-flex">
            <li>
              <a
                className="text-white text-decoration-none"
                href={global.socials.twitter}
                target="_blank"
              >
                <FaTwitter size="1.5rem" />
              </a>
            </li>
            <li className="ms-3">
              <a
                className="text-white text-decoration-none"
                href={global.socials.facebook}
                target="_blank"
              >
                <FaFacebook size="1.5rem" />
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </div>
  );
}
