import nil from "../assets/images/core-team/nil.jpg";
import Alice_Jekayinfa from "../assets/images/core-team/Alice_Jekayinfa.jpg";
import Joshua_Ogunwole from "../assets/images/core-team/Joshua_Ogunwole.jpg";
import Mary_Oladipo from "../assets/images/core-team/Mary_Oladipo.jpg";
import Misbaudeen_Abdul_Hammed from "../assets/images/core-team/Misbaudeen_Abdul_Hammed.jpg";
import Timothy_Olagbemiro from "../assets/images/core-team/Timothy_Olagbemiro.jpg";
import Razaq_O_Rom_Kalilu from "../assets/images/core-team/Razaq_O_Rom_Kalilu.jpg";
import Abdulazeez_T_Lawal from "../assets/images/core-team/Abdulazeez_T_Lawal.jpg";

export default function team() {
  const data = [
    {
      name: "Prof. Timothy Olagbemiro",
      designation: "Chairman",
      bio: "Lorem ipsum dolor sit amet consectetur adipisicing elit. At doloremque perferendis, ab veniam magnam sint cumque et consequuntur optio iusto consectetur quam sapiente illum excepturi ipsa voluptas reiciendis dicta itaque!",
      image: Timothy_Olagbemiro,
      social: {
        twitter: "https://twitter.com/timolagbemiro",
        linkedin:
          "https://www.linkedin.com/in/timothy-olagbemiro-phd-992b4859/",
        researchgate: "nil",
      },
    },
    {
      name: "Prof. Joshua Ogunwole",
      designation: "Vice Chairman I",
      bio: "Lorem ipsum dolor sit amet consectetur adipisicing elit. At doloremque perferendis, ab veniam magnam sint cumque et consequuntur optio iusto consectetur quam sapiente illum excepturi ipsa voluptas reiciendis dicta itaque!",
      image: Joshua_Ogunwole,
      social: {
        twitter: "nil",
        linkedin:
          "https://www.linkedin.com/in/joshua-olalekan-ogunwole-a7077779/",
        researchgate: "nil",
      },
    },
    {
      name: "Prof. Razaq O. Rom Kalilu",
      designation: "Vice Chairman II",
      bio: "Lorem ipsum dolor sit amet consectetur adipisicing elit. At doloremque perferendis, ab veniam magnam sint cumque et consequuntur optio iusto consectetur quam sapiente illum excepturi ipsa voluptas reiciendis dicta itaque!",
      image: Razaq_O_Rom_Kalilu,
      social: {
        twitter: "nil",
        linkedin: "nil",
        researchgate: "nil",
      },
    },
    {
      name: "Prof. Alice Jekayinfa",
      designation: "Treasurer/Financial Secretary",
      bio: "Lorem ipsum dolor sit amet consectetur adipisicing elit. At doloremque perferendis, ab veniam magnam sint cumque et consequuntur optio iusto consectetur quam sapiente illum excepturi ipsa voluptas reiciendis dicta itaque!",
      image: Alice_Jekayinfa,
      social: {
        twitter: "nil",
        linkedin: "https://www.linkedin.com/in/alice-jekayinfa-b601408b/",
        researchgate: "nil",
      },
    },
    {
      name: "Prof. Abdulazeez T. Lawal",
      designation: "Executive Secretary",
      bio: "Lorem ipsum dolor sit amet consectetur adipisicing elit. At doloremque perferendis, ab veniam magnam sint cumque et consequuntur optio iusto consectetur quam sapiente illum excepturi ipsa voluptas reiciendis dicta itaque!",
      image: Abdulazeez_T_Lawal,
      social: {
        twitter: "nil",
        linkedin: "nil",
        researchgate: "nil",
      },
    },
    {
      name: "Prof. Mary O. Oladipo",
      designation: "Publicity Secretary",
      bio: "Lorem ipsum dolor sit amet consectetur adipisicing elit. At doloremque perferendis, ab veniam magnam sint cumque et consequuntur optio iusto consectetur quam sapiente illum excepturi ipsa voluptas reiciendis dicta itaque!",
      image: Mary_Oladipo,
      social: {
        twitter: "nil",
        linkedin: "nil",
        researchgate: "nil",
      },
    },
    {
      name: "Prof. Misbaudeen Abdul-Hammed",
      designation: "General Secretary",
      bio: "Lorem ipsum dolor sit amet consectetur adipisicing elit. At doloremque perferendis, ab veniam magnam sint cumque et consequuntur optio iusto consectetur quam sapiente illum excepturi ipsa voluptas reiciendis dicta itaque!",
      image: Misbaudeen_Abdul_Hammed,
      social: {
        twitter: "nil",
        linkedin:
          "https://www.linkedin.com/in/misbaudeen-abdul-hammed-21889433/",
        researchgate:
          "https://www.researchgate.net/profile/Misbaudeen-Abdul-Hammed",
      },
    },
  ];

  return data;
}
